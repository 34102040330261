import React from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { getTranslatedText as t } from "../../_locale"

const StudentDetailCard = ({ studentResult, setStudentDetail }) => {
    const { exam_code } = useParams()
    const { language } = useSelector((state) => state.language)

    return (
        <div className="bg-white p-5 rounded" style={{ position: "sticky", top: "70px" }}>
            <button type="button" className="close" aria-label="Close" onClick={() => setStudentDetail()}>
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="card-title">{studentResult.student.name + " " + studentResult.student.surname}</h4>
            <h6 className="card-subtitle text-muted">{studentResult.student.username}</h6>

            <div>
                <Link to={`/results/${exam_code}/${studentResult.session.id}`} target="_blank">
                    <button className="box-btn btn-sm mt-2">{t("detailReport")} <i className="fa fa-arrow-right"></i></button>
                </Link>
            </div>

            <span className="text-small text-muted mt-4 mb-3">{t("successRate")}</span>
            <div className="container">
                <div className="row">
                    <div
                        className="progress-circle"
                        style={{
                            background:
                                "conic-gradient(#7367f0 0% " +
                                studentResult.result.score +
                                "%, #e0e0e0 " +
                                studentResult.result.score +
                                "%)"
                        }}
                    >
                        <div className="progress-inner">
                            <span style={{ fontSize: "1.2rem" }}>%</span>
                        </div>
                    </div>
                    <div className="ml-4">
                        <h6>{studentResult.result.score}%</h6>
                        <span className="text-small text-muted">{t("successRate")}</span>
                    </div>
                </div>
            </div>

            <span className="text-small text-muted mt-4 mb-3">{t("subjects")}</span>
            {Object.values(studentResult.result.subjects).map((subject, index) => {
                const total = subject.true_answers + subject.false_answers + subject.empty_answers
                const truePercentage = (subject.true_answers / total) * 100
                const falsePercentage = (subject.false_answers / total) * 100
                const emptyPercentage = (subject.empty_answers / total) * 100
                return (
                    <div key={index} className="mb-3">
                        <h6>{subject.name[language]}</h6>
                        <div className="progress" style={{ height: "6px" }}>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${truePercentage}%`,
                                    backgroundColor: "rgb(75, 192, 192)" // Green color for true
                                }}
                                aria-valuenow={subject.true_answers}
                                aria-valuemin="0"
                                aria-valuemax={total}
                            ></div>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${falsePercentage}%`,
                                    backgroundColor: "rgb(255, 99, 132)" // Red color for false
                                }}
                                aria-valuenow={subject.false_answers}
                                aria-valuemin="0"
                                aria-valuemax={total}
                            ></div>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${emptyPercentage}%`,
                                    backgroundColor: "rgb(255, 206, 86)" // Yellow color for empty
                                }}
                                aria-valuenow={subject.empty_answers}
                                aria-valuemin="0"
                                aria-valuemax={total}
                            ></div>
                        </div>

                        {/* Labels below the progress bar */}
                        <div className="d-flex flex-wrap">
                            {subject.true_answers > 0 && (
                                <div className="d-flex align-items-center mr-3 mb-2">
                                    <span className="progress-default progress-success"></span>
                                    <span className="text-small text-muted">
                                        {t("true")}: {subject.true_answers} ({truePercentage.toFixed(1)}%)
                                    </span>
                                </div>
                            )}
                            {subject.false_answers > 0 && (
                                <div className="d-flex align-items-center mr-3 mb-2">
                                    <span className="progress-default progress-danger"></span>
                                    <span className="text-small text-muted">
                                        {t("false")}: {subject.false_answers} ({falsePercentage.toFixed(1)}%)
                                    </span>
                                </div>
                            )}
                            {subject.empty_answers > 0 && (
                                <div className="d-flex align-items-center mr-3 mb-2">
                                    <span className="progress-default progress-warning"></span>
                                    <span className="text-small text-muted">
                                        {t("empty")}: {subject.empty_answers} ({emptyPercentage.toFixed(1)}%)
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StudentDetailCard
