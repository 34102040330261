import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import { ANS_OPTIONS, FILTER_OPTIONS } from "../../_helpers/constants"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import { sessionActions } from "../../actions/session.actions"
import BadgeYellow from "../../styles/img/badge-2-yellow.svg"

const LeftOptionBar = ({ filteredQuestions, questionsToShowAfterCourseFilters, questionsControlOfSession }) => {
    const myRef = useRef(null)
    const dispatch = useDispatch()

    const { question_show, leftBarFilter, isLeftBarVisible } = useSelector((state) => state.session)


    const { questionsControl } = useSelector((state) => state.questionsControl)

    const selectQuestion = (id) => {
        dispatch(sessionActions.changeShowQuestion(parseInt(id)))
    }

    const gotoQuestion = (key) => {
        if (key !== question_show) {
            dispatch(sessionActions.changeShowQuestion(key))
        }
    }

    const changeFilter = (type) => {
        dispatch(sessionActions.changeLeftBarFilter(type))
    }

    const getAnswerKey = (question) => {
        switch (question.question_type_id) {
            case 1: // Çoktan seçmeli soru
                return question.answers.map((answer, answer_key) => {
                    return (
                        <span
                            key={answer_key}
                            className={`ml-1 mcq-option ${utils.arrayHasLength(question.student_answer) &&
                                question.student_answer[0].answer_id === answer.id
                                ? question.questionStatus !== undefined
                                    ? question.questionStatus
                                        ? "correct"
                                        : "wrong"
                                    : "answer-selected"
                                : ""
                                }`}
                        >
                            {ANS_OPTIONS[answer_key]}
                        </span>
                    )
                })

            case 2: //  Çoklu seçmeli soru
                return question.answers.map((answer, answer_key) => {
                    return (
                        <span
                            key={answer_key}
                            className={`ml-1 mcq-option ${utils.arrayHasLength(question.student_answer) &&
                                question.student_answer.some((perAnswer) => perAnswer.answer_id === answer.id)
                                ? question.questionStatus !== undefined
                                    ? question.questionStatus
                                        ? "correct"
                                        : "wrong"
                                    : "answer-selected"
                                : ""
                                }`}
                        >
                            {ANS_OPTIONS[answer_key]}
                        </span>
                    )
                })

            case 3: //True False
            default:
                return (
                    <span
                        className={`ml-1 mcq-option
                         ${utils.arrayHasLength(question.student_answer) && question.student_answer[0].answer !== null
                                ? question.questionStatus !== undefined
                                    ? question.questionStatus
                                        ? "correct"
                                        : "wrong"
                                    : "answer-selected"
                                : ""
                            }
                        
                        `}
                    >
                        {utils.arrayHasLength(question.student_answer) && question.student_answer[0].answer}
                    </span>
                )
        }
    }

    const getNoQuestionsText = () => {
        switch (leftBarFilter) {
            case "CONTROL":
                return t("noQuestionsTo").replace(":field_name", FILTER_OPTIONS[leftBarFilter].toLowerCase())
            case "EMPTY":
                return t("answeredAllQuestions")
            default:
                return
        }
    }

    if (!isLeftBarVisible && window.innerWidth < 768) return null;

    return (
        <div className="mcq-wrap">
            {/* LEFT OPTION HEADER */}
            <div className="d-block d-md-none position-absolute" style={{ top: '10px', right: '10px' }}>
                <i
                    className="fas fa-times"
                    style={{ fontSize: '20px', cursor: 'pointer', color: '#333555' }}
                    onClick={() => dispatch(sessionActions.toggleLeftBar())}
                ></i>
            </div>
            <div className="mcq-header d-flex flex-row">
                <div className="row mx-2 flex-row">
                    <div className="filter-block">
                        <p
                            className={`left-bar-filter ${leftBarFilter === "ALL" ? "active" : ""}`}
                            onClick={() => {
                                changeFilter("ALL")
                            }}
                        >
                            {FILTER_OPTIONS.ALL}
                            <span>
                                {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                    utils.filterQuestions("ALL", questionsToShowAfterCourseFilters, questionsControl)
                                        .length}
                            </span>
                        </p>
                    </div>
                    <div className="filter-block">
                        <p
                            className={`left-bar-filter ${leftBarFilter === "EMPTY" ? "active" : ""}`}
                            onClick={() => {
                                changeFilter("EMPTY")
                            }}
                        >
                            {FILTER_OPTIONS.EMPTY}
                            <span>
                                {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                    utils.filterQuestions("EMPTY", questionsToShowAfterCourseFilters, questionsControl)
                                        .length}
                            </span>
                        </p>
                    </div>
                    <div className="filter-block">
                        <p
                            className={`left-bar-filter ${leftBarFilter === "CONTROL" || leftBarFilter === "WRONG" ? "active" : ""
                                }`}
                            onClick={() => {
                                utils.checkPageInResult() ? changeFilter("WRONG") : changeFilter("CONTROL")
                            }}
                        >
                            {utils.checkPageInResult() ? FILTER_OPTIONS.WRONG : FILTER_OPTIONS.CONTROL}
                            <span>
                                {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                    utils.filterQuestions(
                                        utils.checkPageInResult() ? "WRONG" : "CONTROL",
                                        questionsToShowAfterCourseFilters,
                                        questionsControl
                                    ).length}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {/* LEFT OPTION BODY */}
            <div className="mcq-content-wrap">
                {filteredQuestions.length > 0 ? (
                    <SimpleBar className="simple-bar-left" id="leftt">
                        <div className="mcq-option-list-area">
                            <div className="mcq-option-list">
                                {filteredQuestions.map((question, key) => {
                                    return (
                                        <div
                                            className={
                                                question.questionId === question_show
                                                    ? "single-mcq-row active"
                                                    : "single-mcq-row"
                                            }
                                            onClick={() => gotoQuestion(question.questionId)}
                                            key={key}
                                            ref={myRef}
                                        >
                                            {questionsControlOfSession &&
                                                questionsControlOfSession.includes(question.id) && (
                                                    <img src={BadgeYellow} alt="" className="trans-badge" />
                                                )}
                                            <span className="sl-no">{question.questionId}.</span>
                                            {getAnswerKey(question)}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </SimpleBar>
                ) : (
                    <span className="filter-null-text">{getNoQuestionsText()}</span>
                )}
            </div>
            {/* LEFT OPTION FOOTER  */}
            {filteredQuestions.length > 0 && (
                <div className="mcq-footer">
                    <label>{t("goToQuestion")}</label>

                    <div className="btn-group dropup ml-3">
                        <button
                            className="btn btn-white border dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {question_show}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {filteredQuestions.length > 0
                                ? filteredQuestions.map((question, key) => {
                                    return (
                                        <span
                                            key={key}
                                            className="dropdown-item"
                                            onClick={() => selectQuestion(question.questionId)}
                                        >
                                            {question.questionId}
                                        </span>
                                    )
                                })
                                : ""}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LeftOptionBar
