import { sessionConstants } from "../_constants/session.constants"
import { utils } from "../_helpers/utils"

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    session: {},
    isLeftBarVisible: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case sessionConstants.SESSION_START_SUCCESS:
            var qid = 0

            function handleQuestionsByShowType(questions, question_show_type) {
                let groupedQuestionsByTypeId = (questions) => {
                    return questions.reduce((acc, question) => {
                        const questionType = utils.checkQuestionIsGroup(question)
                            ? question.children.question_type_id
                            : question.question_type_id
                        if (!acc[questionType]) {
                            acc[questionType] = []
                        }
                        acc[questionType].push(question)
                        return acc
                    }, {})
                }
                let questionsArray = utils.convertObjectToArray(groupedQuestionsByTypeId(questions))
                let groupedQuestionsByShowType =
                    question_show_type === "random" ? utils.shuffleArray(questionsArray) : questionsArray

                let sortedQuestionsByAnswer = (groupedQuestionsByShowType) => {
                    return groupedQuestionsByShowType.map((questionsByType) => {
                        let markedQuestions = []
                        let unMarkedQuestions = []
                        let filteredQuestionsByMark = [markedQuestions, unMarkedQuestions]
                        let markedSingleQuestions = []
                        let unmarkedSingleQuestions = []
                        let markedGroupQuestions = []
                        let unmarkedGroupQuestions = []

                        questionsByType.forEach((question) => {
                            if (question.hasOwnProperty("children")) {
                                if (question.children.student_answer.length > 0) {
                                    markedQuestions.push(question)
                                } else {
                                    unMarkedQuestions.push(question)
                                }
                            } else {
                                if (question.student_answer.length > 0) {
                                    markedQuestions.push(question)
                                } else {
                                    unMarkedQuestions.push(question)
                                }
                            }
                        })

                        filteredQuestionsByMark.forEach((questionsByMark) =>
                            questionsByMark.forEach((question) => {
                                if (question?.student_answer?.length > 0) {
                                    markedSingleQuestions.push(question)
                                }
                                if (question?.student_answer?.length === 0) {
                                    unmarkedSingleQuestions.push(question)
                                }
                                if (question.hasOwnProperty("children")) {
                                    if (question.children.student_answer.length > 0) {
                                        markedGroupQuestions.push(question)
                                    }
                                    if (question.children.student_answer.length === 0) {
                                        let hasMarkedQuestionWithSameParent = markedQuestions.some(
                                            (markedQuestion) => markedQuestion.id === question.id
                                        )

                                        if (hasMarkedQuestionWithSameParent) {
                                            markedGroupQuestions.push(question)
                                        } else {
                                            unmarkedGroupQuestions.push(question)
                                        }
                                    }
                                }
                            })
                        )

                        return {
                            markedGroupQuestions,
                            markedSingleQuestions,
                            unmarkedGroupQuestions,
                            unmarkedSingleQuestions
                        }
                    })
                }

                let sortOrShuffleQuestions = sortedQuestionsByAnswer(groupedQuestionsByShowType).map(
                    (questionsByAnswer) => {
                        const {
                            markedGroupQuestions,
                            markedSingleQuestions,
                            unmarkedGroupQuestions,
                            unmarkedSingleQuestions
                        } = questionsByAnswer

                        const sortedMarkedQuestionsByParentId = utils.sortByField(markedGroupQuestions, "id")
                        let combineMarkedQuestions, combineUnmarkedQuestions, combinedQuestions, newQuestions

                        switch (question_show_type) {
                            case "random":
                                let shuffledUnmarkedSingleQuestions = utils.shuffleArray(unmarkedSingleQuestions)
                                let shuffledUnmarkedGropuQuestionsByParentId = utils.shuffleArray(
                                    Object.values(groupedQuestionsByTypeId(unmarkedGroupQuestions))
                                )
                                combineMarkedQuestions = [...sortedMarkedQuestionsByParentId, ...markedSingleQuestions]
                                combineUnmarkedQuestions = [
                                    ...shuffledUnmarkedGropuQuestionsByParentId,
                                    ...shuffledUnmarkedSingleQuestions
                                ]

                                combinedQuestions = [...combineMarkedQuestions, ...combineUnmarkedQuestions]
                                newQuestions = combinedQuestions.flat()

                                return newQuestions
                            case "in_order":
                            default:
                                combineMarkedQuestions = [...sortedMarkedQuestionsByParentId, ...markedSingleQuestions]
                                combineUnmarkedQuestions = [...unmarkedGroupQuestions, ...unmarkedSingleQuestions]

                                combinedQuestions = [...combineMarkedQuestions, ...combineUnmarkedQuestions]
                                newQuestions = combinedQuestions.flat()

                                return newQuestions
                        }
                    }
                )

                return question_show_type === "random" ? sortOrShuffleQuestions.flat() : questionsArray.flat()
            }

            return {
                session: {
                    ...action.data,
                    courses: action.data.courses.map((course) => ({
                        ...course,
                        questions: handleQuestionsByShowType(course.questions, course.question_show_type)
                            .map((question, key) =>
                                question.children === undefined
                                    ? {
                                          ...question,
                                          answers:
                                              course.question_show_type === "random"
                                                  ? utils.shuffleArray(question.answers)
                                                  : question.answers,
                                          questionId: ++qid,
                                          name: course.name,
                                          session_id: action.data.session_id
                                      }
                                    : {
                                          ...question.children,
                                          answers:
                                              course.question_show_type === "random"
                                                  ? utils.shuffleArray(question.children.answers)
                                                  : question.children.answers,
                                          groupQuestionId: question.id,
                                          parentRoot: question.root,
                                          parentQuestionTypeId: question.question_type_id,
                                          questionId: ++qid,
                                          name: course.name,
                                          session_id: action.data.session_id
                                      }
                            )
                            .flat()
                    }))
                },
                selectedCourseId: "",
                leftBarFilter: "ALL",
                question_show: 1,
                qid: qid
            }
        case sessionConstants.SESSION_START_FAILURE:
            return {
                error: action.error
            }
        case sessionConstants.CHANGE_LEFT_BAR_FILTER:
            return {
                ...state,
                leftBarFilter: action.filter_type
            }

        case sessionConstants.CHANGE_SHOW_QUESTION:
            return { ...state, question_show: action.payload }

        case sessionConstants.CHANGE_SHOWN_COURSE_ID:
            qid = 0
            return {
                ...state,
                session: {
                    ...state.session,
                    courses: state.session.courses.map((course) =>
                        course.id === action.payload
                            ? {
                                  ...course,
                                  questions: course.questions.map((question, key) => ({
                                      ...question,
                                      questionId: ++key,
                                      name: course.name
                                  }))
                              }
                            : {
                                  ...course,
                                  questions: course.questions.map((question, key) => ({
                                      ...question,
                                      questionId: ++qid,
                                      name: course.name
                                  }))
                              }
                    )
                },
                selectedCourseId: action.payload
            }

        case sessionConstants.STORE_OR_UPDATE_ANSWER:
            let isCourseExistedInAnswer = state.answers !== undefined && state.answers[action.name.en] !== undefined

            let existedCourseInAnswer = isCourseExistedInAnswer && state.answers[action.name.en]

            let existedQuestionInAnswer =
                existedCourseInAnswer &&
                existedCourseInAnswer[action.question_id] !== undefined &&
                existedCourseInAnswer[action.question_id]

            let isAnswerExistInAnswer =
                existedQuestionInAnswer &&
                existedQuestionInAnswer.answer.some((perAnswer) => perAnswer.answer_id === action.answer_id)

            let existedQuestionInSession = state.session.courses
                .find((course) => course.name.en === action.name.en)
                .questions.find((question) => question.id === action.question_id)

            return {
                ...state,
                session: {
                    ...state.session,
                    courses: state.session.courses.map((course) =>
                        course.name.en === action.name.en
                            ? {
                                  ...course,
                                  questions: course.questions.map((question, key) =>
                                      question.id === action.question_id
                                          ? {
                                                ...question,
                                                student_answer:
                                                    question.student_answer.length === 0
                                                        ? [
                                                              {
                                                                  answer_id: action.answer_id,
                                                                  answer: action.answer_key
                                                              }
                                                          ]
                                                        : question.question_type_id === 3
                                                        ? !question.student_answer.some(
                                                              (answer) => answer.answer === action.answer_key
                                                          )
                                                            ? [
                                                                  {
                                                                      answer_id: action.answer_id,
                                                                      answer: action.answer_key
                                                                  }
                                                              ]
                                                            : question.student_answer.filter(
                                                                  (answer) => answer.answer_id !== action.answer_id
                                                              )
                                                        : !question.student_answer.some(
                                                              (answer) => answer.answer_id === action.answer_id
                                                          )
                                                        ? question.question_type_id === 2
                                                            ? [
                                                                  ...question.student_answer,
                                                                  {
                                                                      answer_id: action.answer_id,
                                                                      answer: action.answer_key
                                                                  }
                                                              ]
                                                            : [
                                                                  {
                                                                      answer_id: action.answer_id,
                                                                      answer: action.answer_key
                                                                  }
                                                              ]
                                                        : question.student_answer.filter(
                                                              (answer) => answer.answer_id !== action.answer_id
                                                          ).length === 0
                                                        ? []
                                                        : question.student_answer.filter(
                                                              (answer) => answer.answer_id !== action.answer_id
                                                          )
                                            }
                                          : question
                                  )
                              }
                            : course
                    )
                },
                answers: {
                    ...state.answers,
                    [action.name.en]: {
                        ...existedCourseInAnswer,
                        [action.question_id]: !existedQuestionInAnswer
                            ? existedQuestionInSession.student_answer.length > 0 &&
                              existedQuestionInSession.student_answer.some(
                                  (answer) => +answer.answer_id === +action.answer_id
                              )
                                ? {
                                      question_type_id: action.question_type_id,
                                      answer: existedQuestionInSession.student_answer.filter(
                                          (answer) => answer.answer_id !== action.answer_id
                                      )
                                  }
                                : {
                                      question_type_id: action.question_type_id,
                                      answer: [
                                          {
                                              answer_id: action.answer_id,
                                              answer: action.answer_key
                                          }
                                      ]
                                  }
                            : !isAnswerExistInAnswer
                            ? action.question_type_id === 2 // Çoklu seçmeli soru
                                ? {
                                      ...(existedQuestionInAnswer && existedQuestionInAnswer),
                                      question_type_id: action.question_type_id,
                                      answer: [
                                          ...existedQuestionInAnswer.answer,
                                          {
                                              answer_id: action.answer_id,
                                              answer: action.answer_key
                                          }
                                      ]
                                  }
                                : {
                                      question_type_id: action.question_type_id,
                                      answer: [
                                          {
                                              answer_id: action.answer_id,
                                              answer: action.answer_key
                                          }
                                      ]
                                  }
                            : {
                                  ...existedQuestionInAnswer,
                                  answer: existedQuestionInAnswer.answer.filter(
                                      (question_answer) => question_answer.answer_id !== action.answer_id
                                  )
                              }
                    }
                }
            }

        case sessionConstants.SAVE_ANSWER_SUCCESS:
            const { answers: deletedAnswersOnSave, ...newState } = state
            return {
                ...newState
            }

        case sessionConstants.SAVE_ANSWER_FAILURE:
            return {
                ...state,
                error: action.error
            }

        case sessionConstants.FINISH_SESSION_SUCCESS:
            return {
                result: {
                    //.action.response.data
                    ...action.response,
                    session_id: state.session.session_id,
                    exam_code: state.session.exam_code,
                    sessionFinish: true,
                    name: state.session.name
                }
            }
        case sessionConstants.FINISH_SESSION_FAILURE:
            return {
                session: {},
                qid: 0
            }

        case sessionConstants.GET_SESSION_RESULTS_SUCCESS:
            qid = 0
            return {
                session: {
                    ...action.result,
                    courses: action.result.courses
                        ? action.result.courses.map((course) => ({
                              ...course,
                              questions: course.questions
                                  .map((question) =>
                                      !utils.objectHasLength(question.children)
                                          ? {
                                                ...question,
                                                questionId: ++qid,
                                                name: course.name,
                                                session_id: action.result.session_id
                                            }
                                          : {
                                                ...question.children,
                                                groupQuestionId: question.id,
                                                parentRoot: question.root,
                                                parentQuestionTypeId: question.question_type_id,
                                                questionId: ++qid,
                                                name: course.name,
                                                session_id: action.result.session_id
                                            }
                                  )
                                  .flat()
                                  .map((question, key) => {
                                      const { answers, student_answer, question_type_id } = question

                                      const trueAnswerIds = answers
                                          .filter((answer) => answer.true)
                                          .map((answer) => (question_type_id === 3 ? answer.true : answer.id))
                                          .sort((a, b) => a - b)

                                      const studentAnswerIds =
                                          student_answer.length > 0
                                              ? question_type_id === 3
                                                  ? student_answer[0].answer === "T"
                                                      ? [1]
                                                      : [0]
                                                  : student_answer
                                                        .map((answer) => answer.answer_id)
                                                        .sort((a, b) => a - b)
                                              : []

                                      const questionStatus =
                                          studentAnswerIds.length > 0
                                              ? trueAnswerIds.every((id, index) => id === studentAnswerIds[index])
                                              : null

                                      return {
                                          ...question,
                                          questionStatus: questionStatus
                                      }
                                  })
                          }))
                        : []
                },
                selectedCourseId: "",
                leftBarFilter: "ALL",
                question_show: 1,
                qid: 0,
                sessionFinish: false
            }
        case sessionConstants.GET_SESSION_RESULTS_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case sessionConstants.CLEAR_SESSION:
            return {
                session: {},
                qid: 0
            }
        case sessionConstants.TOGGLE_LEFT_BAR:
            return {
                ...state,
                isLeftBarVisible: !state.isLeftBarVisible
            }
        default:
            return state
    }
}
