import { sessionConstants } from "../_constants/session.constants"
import { sessionServices } from "../services/session.services"
import { spinner } from "./spinner"

export const sessionActions = {
    start,
    changeLeftBarFilter,
    changeShowQuestion,
    changeShownCourseId,
    answerQuestion,
    saveAnswer,
    setQuestionControl,
    finishSession,
    clearSession,
    getSessionResult,
    toggleLeftBar
}

function start(code, history) {
    return (dispatch) => {
        dispatch(spinner(true))
        sessionServices
            .start(code)
            .then((response) => {
                dispatch(success(response))
                dispatch(spinner(false))
                history.push("/exam/" + code)
            })
            .catch((error) => {
                dispatch(spinner(false))
                dispatch(failure(error))
            })
    }

    function success(data) {
        return {
            type: sessionConstants.SESSION_START_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: sessionConstants.SESSION_START_FAILURE,
            error
        }
    }
}

function changeLeftBarFilter(filter_type) {
    return {
        type: sessionConstants.CHANGE_LEFT_BAR_FILTER,
        filter_type
    }
}

function changeShowQuestion(data) {
    return {
        type: sessionConstants.CHANGE_SHOW_QUESTION,
        payload: data
    }
}

function changeShownCourseId(data) {
    return {
        type: sessionConstants.CHANGE_SHOWN_COURSE_ID,
        payload: data
    }
}

function answerQuestion(question_id, answer_id, answer_key, name, question_type_id) {
    return {
        type: sessionConstants.STORE_OR_UPDATE_ANSWER,
        question_id,
        answer_id,
        answer_key,
        name,
        question_type_id
    }
}

function saveAnswer(code, sessionId, answers) {
    return (dispatch) => {
        dispatch(spinner(true))
        sessionServices
            .saveAnswer(code, sessionId, answers)
            .then(() => {
                dispatch(success(answers))
                dispatch(spinner(false))
            })
            .catch((error) => {
                dispatch(spinner(false))
                dispatch(failure(error))
            })
    }

    function success(answers) {
        return {
            type: sessionConstants.SAVE_ANSWER_SUCCESS,
            answers
        }
    }

    function failure(error) {
        return {
            type: sessionConstants.SAVE_ANSWER_FAILURE,
            error
        }
    }
}

function setQuestionControl(session_id, question_id) {
    return {
        type: sessionConstants.STORE_OR_DELETE_QUESTION_CONTROL,
        session_id,
        question_id
    }
}

function finishSession(code, sessionId, answers, history) {
    return (dispatch) => {
        dispatch(spinner(true))
        sessionServices
            .finishSession(code, sessionId, answers)
            .then((response) => {
                dispatch(success(response))
                history.push(`/exam-end/${code}/${sessionId}`)
                dispatch(spinner(false))
            })
            .catch((error) => {
                dispatch(spinner(false))
                dispatch(failure(error))
            })
    }

    function success(response) {
        return {
            type: sessionConstants.FINISH_SESSION_SUCCESS,
            response
        }
    }

    function failure(error) {
        return {
            type: sessionConstants.FINISH_SESSION_FAILURE,
            error
        }
    }
}

function clearSession() {
    return {
        type: sessionConstants.CLEAR_SESSION
    }
}

function getSessionResult(exam_code, session_id, history) {
    return (dispatch) => {
        dispatch(spinner(true))
        sessionServices
            .getSessionResult(exam_code, session_id)
            .then((data) => {
                dispatch(success(data))
                history && history.push(`/results/${exam_code}/${session_id}/answers`)
                dispatch(spinner(false))
            })
            .catch((err) => {
                dispatch(spinner(false))
                dispatch(failure(err))
            })
    }

    function success(result) {
        return {
            type: sessionConstants.GET_SESSION_RESULTS_SUCCESS,
            result
        }
    }

    function failure(error) {
        return {
            type: sessionConstants.GET_SESSION_RESULTS_FAILURE,
            error
        }
    }
}

function toggleLeftBar() {
    return {
        type: sessionConstants.TOGGLE_LEFT_BAR
    }
}
