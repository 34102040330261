import React from "react"
import { useSelector } from "react-redux"
import BarChart from "../partials/charts/BarChart"
import DoughnutChart from "../partials/charts/DoughnutChart"

const ExamResultCard = ({ sessionData, classrooms }) => {
    const { language } = useSelector((state) => state.language)
    const showClassrooms = classrooms && classrooms.length > 0
    const classroomsScores = classrooms && [...classrooms.map((clas) => clas.score)]
    const labels = classrooms && [...classrooms.map((clas) => clas.classroom_name[language])]
    const studentResult = JSON.parse(localStorage.getItem('studentResult'))

    return (
        <React.Fragment>
            <div
                className={`col-12 ${showClassrooms ? "col-lg-3" : "col-lg-4"
                    } mt-5 d-flex flex-column justify-content-center align-items-center`}
                style={{ height: "250px" }}
            >
                <DoughnutChart data={sessionData} />
            </div>

            <div
                className={`col-12 ${showClassrooms ? "col-lg-3" : "col-lg-4"
                    } mt-5 d-flex justify-content-center`}
                style={{ height: "250px" }}
            >
                <BarChart dataSet={[sessionData.true_answers, sessionData.false_answers, sessionData.empty_answers]} />
            </div>

            {showClassrooms && (
                <div
                    className="col-12 col-lg-3 mt-5 d-flex justify-content-center"
                    style={{ height: "250px" }}
                >
                    <BarChart dataSet={classroomsScores} labels={labels} />
                </div>
            )}
        </React.Fragment>
    )
}

export default ExamResultCard
