import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import { ANS_OPTIONS } from "../../_helpers/constants"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import { sessionActions } from "../../actions/session.actions"
import Badge1 from "../../styles/img/badge-1.svg"
import BadgeYellow from "../../styles/img/badge-2-yellow-big.svg"
import TooltipContainer from "../partials/TooltipContainer"
import Answers from "./Answers"

const Question = ({
    questions,
    question,
    questionIndex,
    questionsControlOfSession,
    questionsToShowAfterCourseFilters
}) => {
    const dispatch = useDispatch()
    const myRef = useRef(null)

    const { selectedCourseId, question_show, session, leftBarFilter, isLeftBarVisible } = useSelector((state) => state.session)
    const { language } = useSelector((state) => state.language)
    let first_question = questions.length > 0 && questions[0]
    let last_question = questions.length > 0 && questions[questions.length - 1]

    const hasNextQuestion = question_show < last_question.questionId
    const hasPreviousQuestion = question_show > first_question.questionId

    const groupedQuestions = questions.reduce((acc, question) => {
        const groupQuestionId = question.groupQuestionId

        if (groupQuestionId) {
            acc[groupQuestionId] = acc[groupQuestionId] || []
            acc[groupQuestionId].push(question.questionId)
        }

        return acc
    }, {})

    let groupQuestionsArray = utils.objectHasLength(groupedQuestions) && groupedQuestions[question?.groupQuestionId]

    const changeFilterCourse = (course_id) => {
        dispatch(sessionActions.changeShownCourseId(course_id))
    }

    const findQuestionIndex = () => {
        return utils.arrayHasLength(questions) && questions.findIndex((obj) => obj.questionId === question_show)
    }

    const getPreviousQuestion = () => questions[findQuestionIndex() - 1]
    const getNextQuestion = () => questions[findQuestionIndex() + 1]

    const previousQuestion = () => {
        dispatch(sessionActions.changeShowQuestion(getPreviousQuestion().questionId))
        myRef.current && (myRef.current.scrollTop = 0)
    }

    const nextQuestion = () => {
        dispatch(sessionActions.changeShowQuestion(getNextQuestion().questionId))
        myRef.current && (myRef.current.scrollTop = 0)
    }

    const hasNextPrevQuestion = (hasQuestion) => {
        if (hasQuestion === "next") {
            if (hasNextQuestion) {
                nextQuestion()
            }
        }

        if (hasQuestion === "prev") {
            if (hasPreviousQuestion) {
                previousQuestion()
            }
        }
    }

    const toggleFavorite = () => {
        dispatch(sessionActions.setQuestionControl(session.session_id, question.id))
    }

    useEffect(() => {
        if (leftBarFilter === "EMPTY" || leftBarFilter === "CONTROL") {
            if (utils.objectHasLength(getNextQuestion())) {
                dispatch(sessionActions.changeShowQuestion(getNextQuestion().questionId))
            } else if (utils.objectHasLength(getPreviousQuestion())) {
                dispatch(sessionActions.changeShowQuestion(getPreviousQuestion().questionId))
            }
        }
    }, [questions.length, leftBarFilter])

    const handleUserKeyPress = (event) => {
        const { keyCode } = event
        if (keyCode === 37 || keyCode === 38) {
            //ArrowLeft
            hasNextPrevQuestion("prev")
        }
        if (keyCode === 39 || keyCode === 40) {
            // ArrowRight
            hasNextPrevQuestion("next")
        }
    }

    const getCorrectAnswers = (question) => {
        let correctAnswers = question.answers.map((answer, key) =>
            answer.true !== undefined && question.question_type_id === 3
                ? Boolean(answer.true)
                    ? "T"
                    : "F"
                : answer.true
                    ? ANS_OPTIONS[key]
                    : ""
        )
        return correctAnswers.filter((answer) => answer !== "")
    }

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress)
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress)
        }
    }, [handleUserKeyPress])

    const toggleLeftBar = () => {
        dispatch(sessionActions.toggleLeftBar())
    }

    return (
        <div className="exam-question-wrap">
            <div className="question-header">
                <div className="all-question-btns d-flex">
                    <button
                        className={`box-btn ${selectedCourseId === "" ? "active" : ""}`}
                        onClick={() => changeFilterCourse("")}
                    >
                        {t("allLessons")}
                    </button>
                    {session.courses &&
                        session.courses.map((course) => {
                            return (
                                <button
                                    key={course.id}
                                    className={`box-btn ${parseInt(selectedCourseId) === course.id ? "active" : ""}`}
                                    onClick={() => changeFilterCourse(course.id)}
                                >
                                    {course.name[language]}{" "}
                                    <span className="font-weight-light">({course.questions.length})</span>
                                </button>
                            )
                        })}
                </div>
            </div>

            <div className="question-content">
                <div className="single-question-scroll-wrap" ref={myRef}>
                    {questionIndex !== undefined ? (
                        <SimpleBar className="simple-bar">
                            {question && (
                                <>
                                    <div className="position-relative single-question">
                                        {question?.parentRoot && (
                                            <div className="question-text parent mb-5">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: utils.sanitizeHtml(utils.getNumberedParanthesis(
                                                            question.parentRoot,
                                                            groupQuestionsArray
                                                        ))
                                                    }}
                                                />
                                                {session.session_ends_at === null &&
                                                    question?.parentQuestionTypeId === 8 && (
                                                        <p className="mb-3 text-small border-primary p-2 rounded d-flex alig-items-center info">
                                                            <i className="fal fa-exclamation-circle fa-lg mr-3"></i>

                                                            {t("groupQuestion").replace(
                                                                ":field_name",
                                                                groupQuestionsArray.toString()
                                                            )}
                                                        </p>
                                                    )}
                                            </div>
                                        )}
                                        <div className="d-flex">
                                            <div className="question-sl">
                                                <span className="question-number">
                                                    <span className="question-course">
                                                        {question.name[language] + " " + t("question")}
                                                    </span>
                                                    {question_show}.
                                                </span>

                                                {utils.getFirstPath() === "exam" && (
                                                    <img
                                                        src={
                                                            questionsControlOfSession &&
                                                                questionsControlOfSession.includes(question.id)
                                                                ? BadgeYellow
                                                                : Badge1
                                                        }
                                                        onClick={() => toggleFavorite()}
                                                        alt="Badge"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                )}
                                            </div>

                                            <div className="question-text-wrap">
                                                <div>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: utils.sanitizeHtml(question.root) }}
                                                        className="question-text"
                                                    />
                                                    {session.session_ends_at === null &&
                                                        question.question_type_id === 2 && (
                                                            <p className="multiple-key mb-3 text-small border-primary text-center p-2 rounded d-flex alig-items-center">
                                                                <i className="fal fa-exclamation-circle fa-lg mr-3"></i>
                                                                {t("multipleChoice")}
                                                            </p>
                                                        )}
                                                    <Answers question={question} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {getCorrectAnswers(question).length > 0 && (
                                        <div className="d-flex fit-content border rounded p-3">
                                            <h5 className="mr-2">
                                                {getCorrectAnswers(question).length > 1
                                                    ? t("correctAnswers")
                                                    : t("correctAnswer")}
                                            </h5>
                                            {getCorrectAnswers(question).map((answerKey, key) => (
                                                <span key={key} className={`ml-1 mcq-option correct`}>
                                                    {answerKey}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </SimpleBar>
                    ) : (
                        <span className="filter-result-null-text">{t("noQuestion")}.</span>
                    )}
                    {/* QUESTION FOOTER */}
                    <div className="twin-btn-wrap noselect d-flex flex-1 justify-content-between">
                        <TooltipContainer icon={["←", "↑"]}>
                            <a
                                className={
                                    "box-btn style-2 d-inline-flex align-items-center " +
                                    (hasPreviousQuestion ? " twin-btn-wrap-enabled" : "twin-btn-wrap-disabled")
                                }
                                onClick={() => hasNextPrevQuestion("prev")}
                            >
                                <i className="fas fa-chevron-left mr-2"></i>
                                {t("previousQuestion")}
                            </a>
                        </TooltipContainer>
                        <div className="exam-bottom flex-1">
                            <div className="questions-summary d-inline-flex align-items-center mx-4">
                                <div className="summary-block d-block d-md-none">
                                    <i
                                        className={`fas ${isLeftBarVisible ? 'fa-times' : 'fa-bars'} mr-2 fa-2x mx-4`}
                                        onClick={toggleLeftBar}
                                        style={{
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s ease',
                                            transform: isLeftBarVisible ? 'rotate(90deg)' : 'rotate(0)'
                                        }}
                                    ></i>
                                </div>
                                <div>
                                    {" "}
                                    <div className="summary-block">

                                        <span className="questions-summary-number">
                                            {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                                utils.filterQuestions("FILLED", questionsToShowAfterCourseFilters)
                                                    .length}
                                        </span>
                                        {" "}
                                        <span>/</span>
                                        {" "}

                                        <span className="questions-summary-number">
                                            {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                                utils.filterQuestions("EMPTY", questionsToShowAfterCourseFilters)
                                                    .length}
                                        </span>
                                    </div>
                                </div>
                                {utils.checkPageInResult() && (
                                    <div>
                                        <div className="summary-block">
                                            <span className="questions-summary-number">
                                                {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                                    utils.filterQuestions("CORRECT", questionsToShowAfterCourseFilters)
                                                        .length}
                                            </span>
                                            {" "}
                                            <span>/</span>
                                            {" "}
                                            <span className="questions-summary-number">
                                                {utils.arrayHasLength(questionsToShowAfterCourseFilters) &&
                                                    utils.filterQuestions("WRONG", questionsToShowAfterCourseFilters)
                                                        .length}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <TooltipContainer icon={["→", "↓"]}>
                            <a
                                className={
                                    "box-btn style-2 d-inline-flex align-items-center " +
                                    (hasNextQuestion ? "twin-btn-wrap-enabled" : " twin-btn-wrap-disabled")
                                }
                                onClick={() => hasNextPrevQuestion("next")}
                            >
                                {t("nextQuestion")}
                                <i className="fas fa-chevron-right ml-2"></i>
                            </a>
                        </TooltipContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Question
