import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { utils } from "../../_helpers/utils"
import Worker from "../../_helpers/worker"
import WebWorker from "../../_helpers/workerSetup"
import { getTranslatedText as t } from "../../_locale"
import { sessionActions } from "../../actions/session.actions"
import { spinner } from "../../actions/spinner"
import PowerIcon from "../../styles/img/power-icon.svg"
import Timer2 from "../../styles/img/timer-2.svg"
import TimerRed from "../../styles/img/timer-red.svg"
import EmptyAnswersModal from "./EmptyAnswersModal"

const ExamRight = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const callback = useRef()
    const worker = useRef()

    const { session, answers } = useSelector((state) => state.session)
    const { questionsControl } = useSelector((state) => state.questionsControl)

    const [totalRemaining, setTotalRemaining] = useState(0)
    const [total, setTotal] = useState()
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [endtime, setEndtime] = useState(0)
    const [timeInterval, setTimeInterval] = useState(null)
    const [isEmptyAnswersModalOpen, setIsEmptyAnswersModalOpen] = useState(false)
    const [isRunning, setRunning] = useState(false)

    const thresholdSecond = total / 10 //When the counter gets red, default 60 last seconds

    const redStyle = total !== null && totalRemaining > 0 ? totalRemaining < thresholdSecond : false

    const progressStyleMaxWidth = 124

    const progressBarPercent =
        totalRemaining > 0 && total !== null
            ? progressStyleMaxWidth - Math.floor((totalRemaining / total) * progressStyleMaxWidth)
            : 0
    const counterStyle = redStyle
        ? "countdown__timer js-countdown-seconds countdown_red"
        : "countdown__timer js-countdown-seconds "

    const add_minutes = (dt, minutes) => {
        return new Date(dt.getTime() + minutes * 60 * 1000)
    }

    const getEmptyAnswers = () => {
        return (
            utils.objectHasLength(session) &&
            utils.filterQuestions("EMPTY", utils.getAllQuestions(session.courses), questionsControl)
        )
    }

    const finishSession = () => {
        dispatch(spinner(true))
        clearInterval(timeInterval)
        dispatch(sessionActions.finishSession(session.exam_code, session.session_id, answers, history))
    }

    const preFinishExam = () => {
        if (getEmptyAnswers().length > 0) {
            setIsEmptyAnswersModalOpen(true)
        } else {
            finishSession()
        }
    }

    const acceptEmptyQuestions = () => {
        setIsEmptyAnswersModalOpen(false)

        finishSession()
    }

    useEffect(() => {
        if (utils.objectHasLength(session)) {
            setTotal(session.exam_time * 60 * 1000)
            setEndtime(add_minutes(new Date(session.session_starts_at), session.exam_time))
            setRunning(true)
        }
    }, [session])

    const getRemainingTime = () => {
        const milliseconds = Date.parse(endtime) - Date.parse(new Date())
        const seconds = Math.floor((milliseconds / 1000) % 60)
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60)
        const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)
        const warningHour = 0
        const warningSec = 30
        const warningMin = 0
        if (minutes === warningMin && seconds === warningSec && hours === warningHour) {
            utils.createNotification(
                "info",
                t("examWillFinishSoon").replaceAll(":field_name", warningSec),
                t("warning"),
                6000
            )
        }
        if (milliseconds === 0 && seconds === 0 && hours === 0 && minutes === 0) {
            utils.createNotification("info", t("examEndedDueToTimeIsUp"), t("warning"), 7000)
            finishSession()
            setRunning(false)
        }
        setTotalRemaining(milliseconds)
        setSeconds(seconds)
        setMinutes(minutes)
        setHours(hours)
    }

    const handleLeavePage = (e) => {
        const confirmationMessage = t("leaveExamWarning")
        e.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+
        return confirmationMessage // Gecko, WebKit, Chrome <34
    }

    useEffect(() => {
        callback.current = () => {
            if (!isRunning) {
                worker.current.terminate()
                return
            }

            if (session.exam_time === null) {
                worker.current.terminate()
                window.removeEventListener("beforeunload", handleLeavePage)
                history.push(`/`)
                return
            }
            getRemainingTime()
        }
    })

    useEffect(() => {
        worker.current = new WebWorker(Worker)
        worker.current.onmessage = () => {
            callback.current()
        }

        window.addEventListener("beforeunload", handleLeavePage)
        return () => {
            worker.current.terminate()
            window.removeEventListener("beforeunload", handleLeavePage)
        }
    }, [])
    return (
        <>
            {" "}
            <div className="exam-header-right">
                <div className="exam-timer-wrap">
                    <img src={redStyle ? TimerRed : Timer2} alt="" className="exam-timer-icon" />

                    <div className="progress progress-exam">
                        <div
                            className={
                                redStyle ? " progress-bar progressBarStyleRed" : " progress-bar progressBarStyle"
                            }
                            role="progressbar"
                            style={{ width: progressBarPercent }}
                            aria-valuenow={{ progressBarPercent }}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>
                    <div className="countdown" id="js-countdown">
                        <div className="countdown__item">
                            <div className={counterStyle} aria-labelledby="hour-countdown">
                                {("0" + hours).slice(-2)}
                            </div>

                            <div className="countdown__label" id="hour-countdown">
                                {t("hour")}
                            </div>
                        </div>

                        <div className="countdown__item">
                            <div className={counterStyle} aria-labelledby="minute-countdown">
                                {("0" + minutes).slice(-2)}
                            </div>

                            <div className="countdown__label" id="minute-countdown">
                                {t("minutes")}
                            </div>
                        </div>

                        <div className="countdown__item">
                            <div className={counterStyle} aria-labelledby="second-countdown">
                                {("0" + seconds).slice(-2)}
                            </div>

                            <div className="countdown__label" id="second-countdown">
                                {t("seconds")}
                            </div>
                        </div>
                    </div>
                </div>
                <button className="box-btn style-2 in-header" onClick={() => preFinishExam()}>
                    <img src={PowerIcon} alt="" /> <span className="m-purpose">{t("finishExam")}</span>
                </button>
                <EmptyAnswersModal
                    isModalOpen={isEmptyAnswersModalOpen}
                    setModal={() => setIsEmptyAnswersModalOpen(false)}
                    acceptEmptyQuestions={acceptEmptyQuestions}
                    getEmptyAnswerCount={getEmptyAnswers().length}
                />
            </div>
        </>
    )
}

export default ExamRight
