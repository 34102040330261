const base = {
    home: "Anasayfa",
    results: "Sonuçlar",
    examStartDate: "Sınav Başlangıç Tarihi",
    examEndDate: "Sınav Bitiş Tarihi",
    activeExams: "Aktif Sınavlar",
    noActiveExam: "Aktif sınav bulunmamaktadır.",
    question: "Soru",
    noQuestion: "Soru Yok",
    hour: "Saat",
    minutes: "Dakika",
    seconds: "Saniye",
    start: "Başlat",
    examWithCode: "Sınav kodu ile sınava gir",
    enterExamCodeWarning: "Sınavı başlatmak için sınav kodunu girin",
    alreadyFinished: "Bu sınavı bitirdiniz.",
    examCode: "Sınav Kodu",
    upComingExams: "Yaklaşan Sınavlar",
    noUpComingExams: "Yaklaşan sınav bulunmamaktadır.",
    noLaunchedExam: "Başlatılan sınav bulunmamaktadır.",
    turkish: "Türkçe",
    english: "İngilizce",
    logout: "Çıkış",
    exam: "Sınav",
    all: "Tümü",
    empty: "Boş",
    favorited: "Favorilenmiş",
    answered: "Cevaplanan",
    noAnsweredQuestion: "Soru yok",
    goToQuestion: "Soruyu görüntüle",
    save: "Kaydet",
    clear: "Temizle",
    calendar: "Takvim",
    allLessons: "Tüm Dersler",
    finishExam: "Sınavı Bitir",
    typeAnswer: "Cevabınızı buraya yazın..",
    about: "Hakkında",
    examIsOver: "Sınav Bitti",
    examSummary: "Sınav Özeti",
    totalQuestion: "Toplam Soru",
    answers: "Cevaplar",
    correctAnswers: "Doğru cevaplar",
    correctAnswer: "Doğru cevap",
    wrongAnswers: "Yanlış cevaplar",
    emptyQuestion: "Boş Soru",
    afterReview: "Öğretmen İncelemesinden Sonra Not Girişi Yapılacak Soru",
    back: "Geri",
    previousQuestion: "Önceki",
    nextQuestion: "Sonraki",
    notFound: "Sayfa bulunamadı",
    maintanance: "Sitemiz bakımda. Yenileniyoruz...",
    emptyQuestionCount: "Boş bırakılan soru sayısı : ",
    warning: "UYARI",
    warningMessage: "Sınavı bitirmek istediğinizden emin misiniz?",
    control: "Kontrol",

    ///////////////////////////////////////
    //AUTH

    welcome: "Hoşgeldiniz",
    loginWithAccount: "Lütfen hesabınıza giriş yapınız.",
    login: "Giriş Yap",
    responsive: "ApiTwist Sınav Mobil Uyumludur",
    webIntro:
        "Dilediğin cihazdan ApiTwist Sınavı takip edebilir, istatistiklerini görebilir, sınavlarını tekrar çözebilirsin.",
    email: "E-posta",
    usernameOrEmail: "Kullanıcı Adı veya E-posta",
    emailPlaceholder: "E-posta adresinizi giriniz.",
    password: "Şifre",
    forgotPassword: "Şifremi Unuttum",
    notLoggedIn: "Giriş yapılmadı",
    credentialError: "E-mail veya şifre yanlış.",
    tryAgain: "Lütfen tekrar deneyin.",
    detectedLoginDifferentBrowser: "Farklı tarayıcıdan giriş tespit edildi.",
    //////////////////////////////////////

    //////////////////////////////////////
    //VALIDATION
    VAL_REQUIRED: ":field_name alanı gereklidir.",
    VAL_MIN_CHAR: ":field_name en az :min :val_max karakter olmalı.",
    VAL_MAX_CHAR: "en fazla :max",
    VAL_MAIL: " Girilen e-posta adresi geçersiz.",

    //////////////////////////////////////

    //////////////////////////////////////
    //HOME

    min: "dk",
    launchedExams: "Başlatılan Sınavlar",
    continue: "Devam et",
    repeatableExam: "Tekrarlanabilir",
    nonRepeatableExam: "Tekrarlanamaz",
    //////////////////////////////////////

    //SESSION

    examEndedDueToTimeIsUp: "Süre dolduğu için sınav sonlandırıldı.",
    examWillFinishSoon: "Son :field_name saniye! :field_name saniye sonra sınav otomatik olarak kapanacaktır.",
    leaveExamWarning: "Sınavdan çıkmak istediğinizden emin misiniz?",
    controlAnswersInResultsPage: ":field_name sayfasından cevaplarınızı kontrol edin.",
    controlAnswers: "Cevaplarınızı kontrol edin.",
    resultsWillBeDisplayed: "Sonuçlar :field_name arasında mevcut olacak.",
    and: "ve",
    examSaved: "Sınav başarıyla kaydedildi.",
    noQuestionsTo: "Henüz hiçbir soruyu ':field_name edilecek soru' olarak işaretlemediniz.",
    answeredAllQuestions: "Tüm soruları işaretlediniz.",
    score: "Puan",
    true: "Doğru",
    false: "Yanlış",
    show: "Göster",
    lessons: "Dersler",
    displayAnswers: "Cevapları Gör",
    attempt: "Deneme",
    displayResults: "Sonuçları Gör",
    wrong: "Yanlış",
    correct: "Doğru",
    multipleAttempts: "Çoklu Deneme",
    multipleChoice: "Birden fazla şık seçebilirsiniz.",
    examCompleted: "Sınav Tamamlandı",
    startExam: "Sınavı Başlat",
    instructions: "Talimatlar",
    description: "Açıklamalar",
    noInstructions: "Talimat bulunmamaktadır.",
    noDescription: "Açıklama bulunmamaktadır.",
    groupQuestion: ":field_name numaralı soruları yukarıdaki talimatlara göre cevaplayınız.",
    lessonResults: "Ders Sonuçları",
    participation: "Katılım",
    position: "Sıralamadaki yeri",
    successRate: "Başarı Oranı",
    t: "D",
    f: "Y",
    e: "B",
    progress: "Gelişim",
    subjects: "Konu",
    examResult: "Sınav Sonucu",
    noExamResult: "Sınav sonucunu görüntüleyeceğin bir sınava katıl.",
    students: "Öğrenciler",
    questions: "Sorular",
    examTime: "Sınav Süresi",
    average: "Ortalama",
    detailReport: "Detaylı Rapor",
}
export default base
