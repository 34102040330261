export const sessionConstants = {
    SESSION_START_SUCCESS: "SESSION_START_SUCCESS",
    SESSION_START_FAILURE: "SESSION_START_FAILURE",
    CHANGE_LEFT_BAR_FILTER: "CHANGE_LEFT_BAR_FILTER",
    CHANGE_SHOW_QUESTION: "CHANGE_SHOW_QUESTION",
    CHANGE_SHOWN_COURSE_ID: "CHANGE_SHOWN_COURSE_ID",
    STORE_OR_UPDATE_ANSWER: "STORE_OR_UPDATE_ANSWER",
    SAVE_ANSWER_SUCCESS: "SAVE_ANSWER_SUCCESS",
    SAVE_ANSWER_FAILURE: "SAVE_ANSWER_FAILURE",
    STORE_OR_DELETE_QUESTION_CONTROL: "STORE_OR_DELETE_QUESTION_CONTROL",
    FINISH_SESSION_SUCCESS: "FINISH_SESSION_SUCCESS",
    FINISH_SESSION_FAILURE: "FINISH_SESSION_FAILURE",
    CLEAR_SESSION: "CLEAR_SESSION",
    GET_SESSION_RESULTS_SUCCESS: "GET_SESSION_RESULTS_SUCCESS",
    GET_SESSION_RESULTS_FAILURE: "GET_SESSION_RESULTS_FAILURE",
    TOGGLE_LEFT_BAR: 'TOGGLE_LEFT_BAR'
}
